export default [
  {
    title: 'Inicio',
    icon: 'HomeIcon',
    route: 'adm-inicio',
    action: 'ecd',
  },
  {
    title: 'Usuarios',
    icon: 'UsersIcon',
    route: 'adm-usuario',
    action: 'adm',
  },
  {
    title: 'Cliente',
    icon: 'UserIcon',
    route: 'adm-cliente',
    action: 'ecd',
  },
  {
    title: 'Contrato',
    icon: 'FileTextIcon',
    route: 'adm-contrato',
    action: 'ecd',
  },
  {
    title: 'Obras',
    icon: 'TruckIcon',
    route: 'adm-obra',
    action: 'ecd',
  },

  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    action: 'ecd',
    children: [
      {
        title: 'Disciplinas',
        icon: 'FolderIcon',
        route: 'adm-disciplinas',
        action: 'ecd',
      },
      {
        title: 'Atividade',
        icon: 'AlignJustifyIcon',
        route: 'adm-atividades',
        action: 'ecd',
      },
    ],
  },
]
