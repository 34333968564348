export default [
  {
    header: 'paginas',
  },
  {
    title: 'Inicio',
    icon: 'HomeIcon',
    route: 'admin-inicio',
  },
  {
    title: 'Cliente',
    icon: 'UserIcon',
    route: 'admin-clientelista',
  },
  {
    title: 'Contrato',
    icon: 'FileTextIcon',
    route: 'admin-contratolista',
  },
  {
    title: 'Obras',
    icon: 'TruckIcon',
    route: 'admin-obralista',
  },
  {
    title: 'Disciplinas',
    icon: 'FolderIcon',
    route: 'admin-disciplinalista',
  },
  {
    title: 'Atividade',
    icon: 'AlignJustifyIcon',
    route: 'admin-atividadelista',
  },
  {
    title: 'Descrição',
    icon: 'FileIcon',
    route: 'admin-descricaolista',
  },
]
